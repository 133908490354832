<script>
import {ref, onMounted, provide, nextTick} from "vue";
import { useRoute } from "vue-router";
import AppToolbar from "@/components/toolbar/toolbar.vue";
import ListingCard from "@/components/listing-cards/listing-card.vue";

export default {
  name: "Category",
  components: {
    ListingCard,
    AppToolbar,
  },
  setup() {
    const category = ref([]);   // 存储类别数据
    const currentCategory = ref({});  // 当前选择的类别
    const currentProdData = ref([]);  // 当前类别下的产品数据
    const product = ref([]);    // 存储产品数据

    const route = useRoute();

    // 设置当前类别数据
    const setCategoryData = () => {
      const catId = route.query.categoryId;
      const categoryInfo = category.value.find((item) => String(item.id) === String(catId));
      if (categoryInfo) {
        categoryInfo.isActive = true;
        // 使其他类别失活
        category.value.forEach((item) => {
          if (item.id !== categoryInfo.id) item.isActive = false;
        });
        return {
          id: categoryInfo.id,
          isParent: true,
          name: categoryInfo.name,
          categoryDesc: categoryInfo.categoryDesc,
        };
      }
      return {};
    };

    // 设置当前类别的产品数据
    const setProdData = () => {
      const catId = currentCategory.value.id;
      return product.value.filter((item) => String(item.subCategoryId) === String(catId));
    };

    // 加载外部脚本
    const loadScript = (src) => {
      return new Promise((resolve, reject) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = resolve;
        script.onerror = () => reject(new Error(`Failed to load script: ${src}`));
        document.body.appendChild(script);
      });
    };

    // 在 mounted 时加载脚本和处理数据
    onMounted(async () => {
      try {
        await loadScript("../config/categoryNew.js");
        category.value = [...window.categoryData]; // 直接将 window.categoryData 赋给 category

        await loadScript("../config/productNew.js");
        product.value = [...window.products]; // 直接将 window.products 赋给 product

        currentCategory.value = setCategoryData();  // 设置当前选择的类别
        currentProdData.value = setProdData();     // 设置当前类别下的产品数据

        // 在父组件数据加载完后，确保子组件接收到数据
        await nextTick(() => {
          provide("categoryData", currentCategory);
        });
      } catch (error) {
        console.error("Error loading scripts:", error);
      }
    });

    return {
      category,
      currentCategory,
      currentProdData,
      product,
    };
  },
};
</script>

<template>
  <!-- Main Section -->
  <section class="mt-0">
    <!-- Category Top Banner -->
    <div class="py-10 bg-img-cover bg-overlay-dark position-relative overflow-hidden bg-pos-center-center rounded-0"
         :style="{ backgroundImage: `url('/assets/images/banners/banner-category-top.jpg'` }">
      <div class="container-fluid position-relative z-index-20" data-aosb="fade-right" data-aos-delay="300">
        <h1 class="fw-bold display-6 mb-4 text-white">{{ currentCategory.name }}</h1>
        <div class="col-12 col-md-6">
          <p class="text-white mb-0 fs-5">
            {{ currentCategory.categoryDesc }}
          </p>
        </div>
      </div>
    </div>
    <!-- Category Top Banner -->

    <!-- Side Navigation -->
    <div class="row">
      <nav class="col-md-3 col-lg-2 d-md-block sidebar d-md-block col-sm-12 d-none">
        <div class="position-sticky pt-3">
          <ul class="nav flex-column">
            <li class="nav-item" v-for="item in category" :key="item.id">
              <router-link :to="`/category?categoryId=${item.id}`" class="nav-link text-center"
                           :style="item.isActive ? 'color: #0a58ca;font-size:bold' : ''">
                {{ item.name }}
              </router-link>
            </li>
          </ul>
        </div>
      </nav>

      <!-- Main Content -->
      <div class="col-md-9 col-lg-10 col-sm-12">
        <div class="container-fluid" data-aosb="fade-in">
          <!-- Category Toolbar -->
          <AppToolbar :prdCount="currentProdData.length" :c/>
          <!-- /Category Toolbar -->

          <!-- Products -->
          <div class="row g-4">
            <div class="col-12 col-sm-6 col-lg-4" v-for="prod in currentProdData" :key="prod.detailsId">
              <ListingCard :detailsId="`${prod.detailsId}`" :imgUrl="`${prod.img}`" :options="`${prod.options}`"
                           :title="`${prod.name}`" :tagText="prod.tagText"/>
            </div>
          </div>
          <!-- / Products -->
        </div>
      </div>
    </div>

    <!-- /Page Content -->
  </section>
</template>

<style scoped>
</style>

import { createStore } from 'vuex';

const store = createStore({
    state: {
        product: [],
        category: [],
        curCateGory: {},
        detail: [],
    },
    mutations: {
        updateCurrentCategory(state, currentCategory) {
            state.curCateGory = currentCategory;
        }
    },
});

export default store;

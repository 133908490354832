<template>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><router-link to="home">Home</router-link></li>
<!--      <li class="breadcrumb-item">-->
<!--        <router-link-->
<!--          :to="`category?categoryId=${isParent ? id:parentId}`">{{ isParent ? name : parentName }}</router-link></li>-->
<!--      <li class="breadcrumb-item active" v-if="!isParent" aria-current="page">{{ name }}</li>-->
      <li class="breadcrumb-item active" aria-current="page">{{ name }}</li>
    </ol>
  </nav>
</template>
<script>
import { inject } from "vue";

export default {
  name: "Breadcrumbs",
  setup() {
    const categoryData = inject('categoryData');

    // 添加调试输出，检查传递的数据
    console.log('categoryData111:', categoryData)

    return { categoryData };
  }
};
</script>
<template>
  <div class="row py-3 py-lg-0 flex-wrap gx-4 gy-6">
    <!-- menu row -->
    <div class="col" v-for="(group, index) in groupedCategoryData" :key="index">
      <ul class="list-unstyled">
        <li class="dropdown-list-item" v-for="item in group" :key="item.id">
          <router-link :to="`/category?categoryId=${item.id}`" class="dropdown-item">
            {{ item.name }}
          </router-link>
        </li>
      </ul>
    </div>
    <!-- / menu row -->
  </div>
</template>
<script>
import {computed} from 'vue';
import {useStore} from 'vuex';

export default {
  name: "DropdownLinksOne",
  data
      () {
    return {
      groupedCategoryData: []
    }
  },
  setup() {

  }, mounted() {
    const script = document.createElement("script");
    script.src = "../config/categoryNew.js"
    script.onload = () => {
      for (let i = 0; i < window.categoryData.length; i += 3) {
        this.groupedCategoryData.push(window.categoryData.slice(i, i + 3));
      }
    }
    document.body.appendChild(script)
  }
};
</script>
<style scoped>
/* 你可以根据需要保留或修改样式 */
.dropdown-heading {
  font-size: 100% !important;
  color: #323434 !important;
}

.dropdown-item {
  font-size: 100% !important;
  color: #6b7280 !important;
}

.small-text {
  font-size: 90% !important;
}
</style>

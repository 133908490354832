<template>
  <footer class="border-top py-5 {{configClassList}} {{classList}}">
    <div class="container-fluid">
      <div class="d-flex justify-content-between align-items-center flex-column flex-lg-row">
        <div>
          <ul class="list-unstyled">
            <li class="d-inline-block me-1"><a class="text-decoration-none text-dark-hover transition-all"
                                               href="#"><i class="ri-instagram-fill"></i></a></li>
            <li class="d-inline-block me-1"><a class="text-decoration-none text-dark-hover transition-all"
                                               href="#"><i class="ri-facebook-fill"></i></a></li>
            <li class="d-inline-block me-1"><a class="text-decoration-none text-dark-hover transition-all"
                                               href="#"><i class="ri-twitter-fill"></i></a></li>
            <li class="d-inline-block me-1"><a class="text-decoration-none text-dark-hover transition-all"
                                               href="#"><i class="ri-snapchat-fill"></i></a></li>
          </ul>
        </div>
        <div class="d-flex align-items-center justify-content-end flex-column flex-lg-row">
          <p class="small m-0 text-center text-lg-start">&copy; 2024 Hoyoung All Rights Reserved.</p>
          <ul class="list-unstyled mb-0 ms-lg-4 mt-3 mt-lg-0 d-flex justify-content-end align-items-center">
            <li class="bg-light p-2 d-flex align-items-center justify-content-center me-2">
              <i class="pi pi-sm pi-paypal"></i></li>
            <li class="bg-light p-2 d-flex align-items-center justify-content-center me-2">
              <i class="pi pi-sm pi-mastercard"></i></li>
            <li class="bg-light p-2 d-flex align-items-center justify-content-center me-2">
              <i class="pi pi-sm pi-american-express"></i></li>
            <li class="bg-light p-2 d-flex align-items-center justify-content-center"><i
                class="pi pi-sm pi-visa"></i>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: "AppFooter",
};
</script>
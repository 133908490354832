<template>
  <nav class="m-0" style="position: relative;z-index: 888" aria-label="breadcrumb">
    <ol class="breadcrumb m-0">
      <li class="breadcrumb-item breadcrumb-light">
        <router-link to="home">Home</router-link>
      </li>
      <li class="breadcrumb-item breadcrumb-light">
        <router-link
            :to="`category?categoryId=${curCateGory.id}`"
        >{{ curCateGory.name }}
        </router-link>
      </li>
      <li class="breadcrumb-item breadcrumb-light active" aria-current="page">{{ detailsName }}</li>
    </ol>
  </nav>
</template>

<script>
export default {
  name: "BreadcrumbsLight",
  props: {
    catId: String,
    detailsName: String,
  },
  data() {
    return {
      category: [],
      curCateGory: {},
    };
  },
  async mounted() {
    // 优化：检查脚本是否已经加载
    if (!window.categoryData) {
      try {
        await this.loadScript("../config/categoryNew.js");
        this.category = window.categoryData || []; // 确保 window.categoryData 存在
        this.setCategoryData();
      } catch (error) {
        console.error("Error loading script:", error);
      }
    } else {
      this.category = window.categoryData; // 如果脚本已经加载，直接使用
      this.setCategoryData();
    }
  },
  methods: {
    // 加载外部脚本的函数
    loadScript(src) {
      return new Promise((resolve, reject) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = () => resolve();
        script.onerror = () => reject(new Error(`Failed to load script: ${src}`));
        document.body.appendChild(script);
      });
    },

    // 根据提供的 catId 设置类别数据
    setCategoryData() {
      const catId = this.catId;
      let categoryData = {};

      this.category.forEach((item) => {
        if (String(item.id) === String(catId)) {
          categoryData = {
            id: item.id,
            isParent: true,
            name: item.name,
          };
        }

        // 如果有 subCategories，查找子类别
        if (item.subCategories) {
          item.subCategories.forEach((subItem) => {
            if (String(subItem.id) === String(catId)) {
              categoryData = {
                id: subItem.id,
                isParent: false,
                name: subItem.name,
                parentId: item.id,
                parentName: item.name,
              };
            }
          });
        }
      });

      this.curCateGory = categoryData;
    },
  },
};
</script>

<style scoped>
/* 可根据需要添加样式 */
</style>

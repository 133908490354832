<script>
import { ref, onMounted, inject } from "vue";
import BreadcrumbsLight from "@/components/breadcrumbs/breadcrumbs-light.vue";
import ProductGallery from "@/components/product/product-gallery.vue";
import ProductInfo from "@/components/product/product-info.vue";
import { useRoute } from "vue-router";

export default {
  name: "AppDetail",
  components: {
    ProductInfo,
    ProductGallery,
    BreadcrumbsLight,
  },
  setup() {
    // 通过 inject 获取上级组件提供的 categoryData
    const categoryData = inject("categoryData");

    // 获取当前产品 ID
    const route = useRoute();
    const detailsId = route.query.detailsId;

    // 产品详情数据
    const detail = ref([]);

    // 当前选择的产品
    const details = ref({});

    // 加载外部脚本并获取数据
    const loadScript = (src) => {
      return new Promise((resolve, reject) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = resolve;
        script.onerror = () => reject(new Error(`Failed to load script: ${src}`));
        document.body.appendChild(script);
      });
    };

    // 在 mounted 生命周期时加载外部脚本
    onMounted(async () => {
      try {
        await loadScript("../config/detailNew.js");
        detail.value = [...window.detail];

        // 根据 detailsId 查找产品详情
        details.value = detail.value.find((item) => String(item.id) === detailsId) || {};

      } catch (error) {
        console.error("Error loading script:", error);
      }
    });

    // 获取上级路由传递的类别数据
    const curCateGory = route.params.curCateGory;

    return {
      detail,
      details,
      categoryData,
      curCateGory
    };
  },
};
</script>

<template>
  <!-- Main Section-->
  <section class="mt-0">
    <!-- Page Content Goes Here -->

    <!-- Breadcrumbs-->
    <div class="bg-dark py-6">
      <div class="container-fluid">
        <BreadcrumbsLight :detailsName="details.title" :catId="details.parentCategoryId" />
      </div>
    </div>
    <!-- / Breadcrumbs-->

    <div class="container-fluid mt-5">
      <!-- Product Top Section-->
      <div class="row g-9" data-sticky-container>

        <!-- Product Images-->
        <div class="container-fluid pl-7 mt-5">
          <div class="row g-9" data-sticky-container>

            <!-- Product Images-->
            <div class="col-12 col-md-5 col-lg-7"
                 style="padding-left: 0!important;padding-right: 0!important; padding-top: 1rem">
              <ProductGallery :imgs="details.imgs" />
            </div>
            <!-- /Product Images-->

            <!-- Product Information-->
            <div class="col-12 col-md-6 col-lg-5 mx-auto"
                 style="padding-left: 0!important;padding-right: 0!important;margin-top: 5rem !important;">
              <div class="sticky-top top-5">
                <ProductInfo :description="details.description" :title="details.title" />
              </div>
            </div>
            <!-- / Product Information-->
          </div>
        </div>
        <!-- /Product Images-->
      </div>
      <!-- / Product Top Section-->
    </div>
    <!-- /Page Content -->
  </section>
  <!-- / Main Section-->
</template>

<style scoped>
</style>
